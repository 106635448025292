<template>
    <div >
   
        <div v-if="deviceInfo" class="row margin-top-40">
            <div>
                <table class='table' >
                    <tr>
                        <th localized>Time</th>
                        <td>
                            {{deviceInfo.latestUpdateTime}}
                            <span v-if="deviceInfo.latestUpdateTime">
                                ({{deviceInfo.latestUpdateInterval}}
                                {{(deviceInfo.latestUpdateIntervalType)}})
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th localized>Device number</th>
                        <td>{{deviceInfo.deviceNumber}}</td>
                    </tr>
                    <tr>
                        <th localized>Description</th>
                        <td ng-bind-html="formatMultiLine(deviceInfo.description)"></td>
                    </tr>
                    <tr>
                        <th localized>Groups</th>
                        <td>
                            <span v-for="group in deviceInfo.groups" :key="group.name">
                                {{group.name}}<br/>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th localized>IMEI</th>
                        <td>{{deviceInfo.imeiRequired}}</td>
                    </tr>
                    <tr>
                        <th localized>IMEI Actual</th>
                        <td>{{deviceInfo.imeiActual}}</td>
                    </tr>
                    <tr>
                        <th localized>Phone</th>
                        <td>{{deviceInfo.phoneNumberRequired}}</td>
                    </tr>
                    <tr>
                        <th localized>Phone</th>
                        <td>{{deviceInfo.phoneNumberActual}}</td>
                    </tr>
                    <tr>
                        <th localized>ICCID</th>
                        <td>{{deviceInfo.iccid}}</td>
                    </tr>
                    <tr v-if="deviceInfo.imsi">
                        <th localized>IMSI</th>
                        <td>{{deviceInfo.imsi}}</td>
                    </tr>
                    <tr v-if="deviceInfo.imei2">
                        <th localized>2nd SIM IMEI</th>
                        <td>{{deviceInfo.imei2}}</td>
                    </tr>
                    <tr v-if="deviceInfo.phone2">
                        <th localized>2nd Phone</th>
                        <td>{{deviceInfo.phone2}}</td>
                    </tr>
                    <tr v-if="deviceInfo.iccid2">
                        <th localized>2nd ICCID</th>
                        <td>{{deviceInfo.iccid2}}</td>
                    </tr>
                    <tr v-if="deviceInfo.imsi2">
                        <th localized>2nd IMSI</th>
                        <td>{{deviceInfo.imsi}}</td>
                    </tr>
                    <tr>
                        <th localized>Serial Number</th>
                        <td>{{deviceInfo.serial}}</td>
                    </tr>
                    <tr>
                        <th localized>CPU architecture</th>
                        <td>{{deviceInfo.cpu}}</td>
                    </tr>
                    <tr>
                        <th localized>Permission to install as device administrator</th>
                        <td>
                            <span>
                                {{( deviceInfo.adminPermission)}}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th localized>Permission to overlay on top of other windows</th>
                        <td>
                            <span >
                                {{( deviceInfo.overlapPermission)}}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th localized>Permission to access the use of history</th>
                        <td>
                            <span ng-class="{'device-data-mismatch': !deviceInfo.historyPermission}">
                                {{(deviceInfo.historyPermission)}}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th localized>Permission to access the accessibility services</th>
                        <td>
                            <span>
                                {{(!!deviceInfo.accessibilityPermission)}}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th localized>Model</th>
                        <td>{{deviceInfo.model}}</td>
                    </tr>
                    <tr>
                        <th localized>OS Version</th>
                        <td>{{deviceInfo.osVersion}}</td>
                    </tr>
                    <tr>
                        <th localized>Battery Charge</th>
                        <td ng-class="{'device-data-mismatch': deviceInfo.batteryLevel < 20}">
                            {{deviceInfo.batteryLevel ? (deviceInfo.batteryLevel + '%') : undefined}}
                        </td>
                    </tr>
                    <tr>
                        <th localized>MDM Mode</th>
                        <td v-if="deviceInfo.mdmMode !== undefined">{{( deviceInfo.mdmMode)}}</td>
                    </tr>
                    <tr>
                        <th localized>Launcher variant</th>
                        <td>{{deviceInfo.launcherType}}</td>
                    </tr>
                    <tr>
                        <th localized>Default launcher</th>
                        <td>{{deviceInfo.launcherPackage}}</td>
                    </tr>
                </table>
            </div>
    
            <div class="col-sm-offset-2 col-sm-5" v-if="latestDynamicData">
                <table class='table'>
                    <tr>
                        <th localized>Time</th>
                        <td>
                            {{latestDynamicData.latestUpdateTime}}
                            <span v-if="latestDynamicData.latestUpdateTime">
                                ({{latestDynamicData.latestUpdateInterval}}
                                {{(latestDynamicData.latestUpdateIntervalType)}})
                            </span>
                        </td>
                    </tr>
    
                    <!-- Main -->
                    <tr v-if="dynamicDeviceData.deviceDataIncluded.value">
                        <th colspan="2" localized class="text-center">Device Info</th>
                    </tr>
                    <tr v-for="field in dynamicDataDeviceFieldsOrder" :key="field">
                        <th>{{(item.name)}}</th>
                        <td v-if="item.isBoolean">
                            {{(item.value)}}
                        </td>
                        <td v-if="!item.isBoolean">
                            {{item.value}}
                        </td>
                    </tr>
    
                    <!-- Wi-Fi -->
                    <tr v-if="dynamicWifiData.wifiDataIncluded.value">
                        <th colspan="2" localized class="text-center">WI-FI</th>
                    </tr>
                    <tr v-for="field in dynamicDataWifiFieldsOrder" :key="field"
                        >
                        <th>{{(item.name)}}</th>
                        <td v-if="item.isBoolean">
                            {{(item.value)}}
                        </td>
                        <td v-if="item.isEnumerated">
                            {{(field + '.' + item.value)}}
                        </td>
                        <td v-if="!item.isBoolean && !item.isEnumerated">
                            {{item.value}}
                        </td>
                    </tr>
    
                    <!-- GPS -->
                    <tr v-if="dynamicGpsData.gpsDataIncluded.value">
                        <th colspan="2" localized class="text-center">GPS</th>
                    </tr>
                    <tr v-for="field in dynamicDataGpsFieldsOrder" :key="field"
                        >
                        <th>{{(item.name)}}</th>
                        <td v-if="item.isBoolean">
                            {{( item.value)}}
                        </td>
                        <td v-if="item.isEnumerated">
                            {{(field + '.' + item.value)}}
                        </td>
                        <td v-if="!item.isBoolean && !item.isEnumerated">
                            {{item.value}}
                        </td>
                    </tr>
    
                    <!-- Mobile #1 -->
                    <tr v-if="dynamicMobile1Data.mobile1DataIncluded.value">
                        <th colspan="2" localized class="text-center">Mobile1</th>
                    </tr>
                    <tr v-for="field in dynamicDataMobile1FieldsOrder" :key="field"
                    >
                        <th>{{(item.name)}}</th>
                        <td v-if="item.isBoolean">
                            {{(item.value)}}
                        </td>
                        <td v-if="item.isEnumerated">
                            {{(field + '.' + item.value)}}
                        </td>
                        <td v-if="!item.isBoolean && !item.isEnumerated">
                            {{item.value}}
                        </td>
                    </tr>
    
                    <!-- Mobile #2 -->
                    <tr v-if="dynamicMobile2Data.mobile2DataIncluded.value">
                        <th colspan="2" localized class="text-center">Mobile2</th>
                    </tr>
                    <tr v-for="field in dynamicDataMobile2FieldsOrder"
                        v-if="dynamicMobile2Data && dynamicMobile2Data.hasOwnProperty(field)"
                        >
                        <th>{{(item.name)}}</th>
                        <td v-if="item.isBoolean">
                            {{(item.value)}}
                        </td>
                        <td v-if="item.isEnumerated">
                            {{(field + '.' + item.value)}}
                        </td>
                        <td v-if="!item.isBoolean && !item.isEnumerated">
                            {{item.value}}
                        </td>
                    </tr>
    
                </table>
            </div>
        </div>
    
        <div v-if="deviceInfo" class="row margin-top-40">
            <div class="text-center">
                <h5 localized>Applications Installed</h5>
            </div>
            
            <table class="table" id="device_table">
                <tr>
                    <th localized>Name</th>
                    <th localized>Package</th>
                    <th localized>Installed version</th>
                    <th localized>Required version</th>
                </tr>
    
                <tr v-for="app in deviceInfo.applications">
                    <td>{{app.applicationName}}</td>
                    <td>{{app.applicationPkg}}</td>
                    <td>{{app.versionInstalled}}</td>
                    <td>{{app.versionRequired}}</td>
                </tr>
            </table>
    
        </div>
    
    </div>
    </template>

<script>
 import apis from "@/apis";
  import axios from "axios";
export default {
    props: ["data"],
    data() {
    return {
        deviceInfo:'',
    }
},
methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.$emit("submit");
    },
    
  },
  mounted() {
    console.log(this.data);
    var data = {
        

            "groupId": 0,
            "configurationId": 0,
            "pageNum": 0,
            "pageSize": 0,
            "sortBy": null,
            "sortDir": "0",
            "value":this.data
    
        };
        this.loading = true;
        var promise = apis.deviceinfo(data, this.id);
        promise.then((response) => {
            
            this.deviceInfo=response.data.data;
            console.log(response.data.data);
        });
  },



}
</script>

<style scoped>
    table {
  border-collapse: collapse;
  border: 1px solid black;
 
}
#device_table{
    margin: auto;
}

tr + tr {
  border-top: 1px solid black;
}
td{
    border: 1px solid black;
}
</style>

