<template>
    
    <div class="grid">
        <loading
      :active="isLoadingModel"
      :can-cancel="true"
      
      color="#ffc107"
      :is-full-page="fullPage"
    ></loading>
      <div class="col-12">
        <div class="card">
          <Toast />
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <InputText v-model="search" placeholder="Search..." @change="get_list(),get_count()"/>
                <!-- <Button
                  label="New"
                  icon="pi pi-plus"
                  class="p-button-success mr-2"
                  @click="openNew"
                /> -->
                <!-- <Button
                  label="Delete"
                  icon="pi pi-trash"
                  class="p-button-danger"
                  @click="confirmDeleteSelected"
                  :disabled="!selectedProducts || !selectedProducts.length"
                /> -->
                
                &nbsp;
                <!-- <Button
                  icon="pi pi-sync"
                  class="p-button-info mr-2"
                  @click="get_list"
                /> -->
              </div>
            </template>
  
            <template v-slot:end>
              <!-- <FileUpload
                mode="basic"
                accept="image/*"
                :maxFileSize="1000000"
                label="Import"
                chooseLabel="Import"
                class="mr-2 inline-block"
              /> -->
              <!-- <Button
                label="Export"
                icon="pi pi-upload"
                class="p-button-help"
                @click="exportCSV($event)"
              /> -->
            </template>
          </Toolbar>
 
          <DataTable
            :loading="loading"
            ref="dt"
            :lazy="true"
            :totalRecords="totalRecords"
            :paginator="true"
            :value="products"
            v-model:selection="selectedProducts"
            :dataKey="columns[0]"
            :rows="limit"
            :filters="filters"
            @page="onPage($event)"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25, totalRecords]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll"
            style="font-size: 12px"
            :rowHover="true" showGridlines
          ><template #empty>
          <b style="text-align:center">No records found.</b>
        </template>
            <template #header>
              <div
                class="
                  flex flex-column
                  md:flex-row md:justify-content-between md:align-items-center
                "
              >
                <h5 class="m-0"><b>Device Master</b></h5>
                <h6 class="pull-right" >Total Records: {{totalRecords}}</h6>
                <Button icon="pi pi-refresh" @click="get_list()" />
                <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                  <i class="pi pi-search" />
                                  <InputText v-model="filters['global'].value" placeholder="Search..." />
                              </span> -->
              </div>
            </template>
  
            <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
            <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

            <Column headerStyle="min-width:3rem;">
              <template #body="slotProps">
               
                <Button
                  label="View"
                  class="p-button-rounded p-button-success mr-2"
                  @click="editProduct(slotProps.data)"
                />
                <Button
                  label="Reboot"
                  class="p-button-rounded p-button-warning mt-2"
                  @click="reboot(slotProps.data)"
                />
              </template>
            </Column>



            <Column header="Sr No" style="min-width: 5rem;text-align: center;">
              <template #body="{ index}">
              <span v-if="page_no==1">{{ +index+ +1 }}</span>
                <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
              </template>
            </Column>

            <Column header="Status" style="min-width: 5rem;text-align: center;">
              <template #body="{ data}">
              <span v-if="data.statusCode">
                <Badge v-if="data.statusCode=='green'" severity="success"></Badge>
                <Badge v-else-if="data.statusCode=='yellow'"  severity="warning"></Badge>
                <Badge v-else-if="data.statusCode=='red'" severity="danger"></Badge>
                <Badge v-else severity="info"></Badge>
              </span>
             
              </template>
            </Column>
  
            
            <Column header="Date" style="min-width: 5rem" :field="columns[1]">
              <template #body="{ data }">
               
                <span v-if="data.enrollTime">{{timestampToDateTime(data.enrollTime)}}</span>
                <span v-else>{{"-"}}</span>
              </template>
            </Column>
            
            <Column header="Device" style="min-width: 5rem" :field="columns[2]">
              <template #body="{ data }">
                {{ data.info.deviceId }}
              </template>
            </Column>
  
            <Column header="IMEI No." style="min-width: 5rem" :field="columns[3]">
              <template #body="{ data }">
                {{ data.info.imei }}
              </template>
            </Column>
            <Column header="Model" style="min-width: 5rem" :field="columns[4]">
              <template #body="{ data }">
                {{ data.info.model }}
              </template>
            </Column>
            
            
  
  
  
            <!-- <Column headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success mr-2"
                  @click="editProduct(slotProps.data)"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-warning mt-2"
                  @click="confirmDeleteProduct(slotProps.data)"
                />
              </template>
            </Column> -->
          </DataTable>
  
          <Sidebar
          v-model:visible="productDialog"
          :baseZIndex="1000"
          position="full"
        >
        <DeviceInfoComponent v-if="product.number" :data="product.number"> </DeviceInfoComponent>

            
          </Sidebar>
  
          <Dialog
            v-model:visible="deleteProductDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product"
                >Are you sure you want to delete <b>{{ product.name }}</b
                >?</span
              >
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductDialog = false"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                @click="deletesyllabus"
              />
            </template>
          </Dialog>
  
          <Dialog
            v-model:visible="deleteProductsDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product"
                >Are you sure you want to delete the selected products?</span
              >
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductsDialog = false"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteSelectedProducts"
              />
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { FilterMatchMode } from "primevue/api";
  import ProductService from "../service/ProductService";

  //import deviceinfo from "@/components/DeviceInfoComponent";
  //import * as Survey from "survey-vue";
  
  // import axios from "axios";
  import apis from "@/apis";
  import axios from "axios";
import DeviceInfoComponent from "../components/DeviceInfoComponent.vue";
  
  export default {
    components:{DeviceInfoComponent},
    data() {
      return {
        search:'',
        page_no:1,
              isLoading:false,
              isLoadingModel:false,
              loading: false,
              fullPage:true,
        file_attachment: "",
        dropdownItems: [
          { name: "Active", value: "Active" },
          { name: "InActive", value: "InActive" },
                  { name: "Deleted", value: "Deleted" }
        ],
        dropdownItemsyear: [
          { name: 2023, value: 2023 },
          { name: 2022, value: 2022 },
                  { name: 2021, value: 2021 },
          { name: 2020, value: 2020 },
          { name: 2019, value: 2019 },
          { name: 2018, value: 2018 },
          { name: 2017, value: 2017 }
        ],
        products: null,
        productDialog: false,
        deleteProductDialog: false,
        deleteProductsDialog: false,
        lazyParams: {},
        product: {},
        selectedProducts: null,
        filters: {},
        offset: 1,
        selectstatus: { name: "", value: "" },
        selectstatusyear: { name: "", value: "" },
        limit: 10,
        columns: [],
        head: [
          "Data Usages",
          "Network Connectivity",
          "Students",
          "Teachers",
          "Student Registration",
          "News Master",
          "Government Panel",
          "Feedback Master",
          "User Queries",
          "Schemes Master",
          "Notice Master",
          "Subject Master",
          "Teacher Schedule Master",
          "Teacher Schedule DE Master",
          "Query Types Master",
          "Sub Query Types Master",
          "Admin Users",
          "Application Roles",
          "Departments",
          "Cast Master",
          "Year Master",
          "District Master",
          "Student Attendance",
          "Student Document",
          "Teachers",
          "Radical ids",
          "Subject Topics",
          "Query Comments",
          "Student Submit Answers",
          "Mock Tests",
          "Mock Test Papers",
          "Mock Test Results",
          "Video",
          "Syllabus"
        ],
        pageno: 1,
        submitted: false,
        totalRecords: 0,
        id: "",
        statuses: [
          { label: "INSTOCK", value: "instock" },
          { label: "LOWSTOCK", value: "lowstock" },
          { label: "OUTOFSTOCK", value: "outofstock" },
        ],
      };
    },
    productService: null,
    async created() {
      this.productService = new ProductService();
      this.initFilters();
    },
    async mounted() {
      // this.productService.getProducts().then(data => this.products = data);
      this.id = this.$route.params.id;
  
      this.lazyParams = {
        first: 0,
        rows: this.limit,
        sortField: null,
        sortOrder: null,
        filters: this.filters,
      };
      if (this.totalRecords == 0) {
        await this.get_count();
      }
      await this.get_list();
      //alert(this.id);
    },
    watch: {
      async $route() {
        this.products = [];
        this.columns = [];
        this.totalRecords = 0;
        this.id = this.$route.params.id;
        if (this.id) {
          await this.get_count();
          this.get_list();
        }
      },
    },
    methods: {
      handleFileUpload() {
        this.file_attachment = this.$refs.file_attachment.files[0];
        this.product.imagE_URL="";
  
        //alert(this.$refs.file_attachment.files[0].name);
      },
      async onPage(event) {
        this.lazyParams = event;
        this.page_no = event.page + 1;
        this.limit = event.rows;
        // console.log(event);
        await this.get_count();
        if (this.totalRecords > 0) {
          this.get_list();
        }
      },
      get_count: function () {
        var data = {
            "groupId": 5,
            "configurationId": -1,
            "pageNum": this.page_no,
            "pageSize": this.limit,
            "sortBy": null,
            "sortDir": "ASC",
            "value":this.search
        };
        this.loading = true;
        var promise = apis.devicelist(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.totalRecords = response.data.data.devices.totalItemsCount;
          //alert(this.totalRecords);
        });
  
        console.log(data);
      },
      timestampToDateTime(timestamp) {
  const date = new Date(timestamp);
  return date.toLocaleString();
},

      get_list: function () {
        var data = {
        //   Limit: this.limit,
        //   page_no: this.page_no,
        //   count: false,

            "groupId": 5,
            "configurationId": -1,
            "pageNum": this.page_no,
            "pageSize": this.limit,
            "sortBy": null,
            "sortDir": "ASC",
            "value":this.search
    
        };
        this.loading = true;
        var promise = apis.devicelist(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.products = response.data.data.devices.items;
          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
              this.columns = Object.keys(this.products[0]);
            }
          }
        });
  
        console.log(data);
      },
      formatCurrency(value) {
        if (value)
          return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        return;
      },
      openNew() {
        this.product = {};
        this.submitted = false;
        this.selectstatus={name:"",value:""};
         this.selectstatusyear={name:"",value:""};
        this.productDialog = true;
      },
      hideDialog() {
        this.productDialog = false;
        this.submitted = false;
      },
      
      saveProduct() {
        this.submitted = true;
        if (this.product.name.trim()) {
          if (this.product.id) {
            this.product.inventoryStatus = this.product.inventoryStatus.value
              ? this.product.inventoryStatus.value
              : this.product.inventoryStatus;
            this.products[this.findIndexById(this.product.id)] = this.product;
            this.$toast.add({
              severity: "success",
              summary: "Successful",
              detail: "Product Updated",
              life: 3000,
            });
          } else {
            this.product.id = this.createId();
            this.product.code = this.createId();
            this.product.image = "product-placeholder.svg";
            this.product.inventoryStatus = this.product.inventoryStatus
              ? this.product.inventoryStatus.value
              : "INSTOCK";
            this.products.push(this.product);
            this.$toast.add({
              severity: "success",
              summary: "Successful",
              detail: "Product Created",
              life: 3000,
            });
          }
          this.productDialog = false;
          this.product = {};
        }
      },
      editProduct(product) {
        this.product = { ...product };
        // this.router.push()
        this.productDialog = true;
      },
      reboot(product)
      {
        this.product = { ...product };


        var data = {
                value:this.product.id
        };
        this.loading = true;
        var promise = apis.devicereboot(data, this.id);
        promise.then((response) => {
            this.loading = false;
            this.$toast.add({
          severity: "success",
          summary: "Successful",
          detail: "Reboot Successfully",
          life: 3000,
        });
        });
       
        
      },
      confirmDeleteProduct(product) {
        this.product = product;
        this.deleteProductDialog = true;
      },
      deleteProduct() {
        this.products = this.products.filter((val) => val.id !== this.product.id);
        this.deleteProductDialog = false;
        this.product = {};
        this.$toast.add({
          severity: "success",
          summary: "Successful",
          detail: "Product Deleted",
          life: 3000,
        });
      },
  
      deletesyllabus() {
        if (this.product.id) {
          var data = {
            id: this.product.id,
          };
          this.isLoadingModel = true;
          var promise = apis.deletesyllabusmaster(data);
          promise.then((responseapi) => {
            if (responseapi.status == 200) {
              this.deleteProductDialog = false;
              this.isLoadingModel = false;
              this.$swal(responseapi.data);
              // this.hideDialog1();
              
              this.get_list();
            } else {
              this.isLoadingModel = false;
              this.deleteProductDialog = false;
              this.$swal("error to upload file");
            }
          });
        }
      },
      findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
            index = i;
            break;
          }
        }
        return index;
      },
      createId() {
        let id = "";
        var chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 5; i++) {
          id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
      },
      exportCSV() {
        this.$refs.dt.exportCSV();
       },
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },
      deleteSelectedProducts() {
        this.products = this.products.filter(
          (val) => !this.selectedProducts.includes(val)
        );
        this.deleteProductsDialog = false;
        this.selectedProducts = null;
        this.$toast.add({
          severity: "success",
          summary: "Successful",
          detail: "Products Deleted",
          life: 3000,
        });
      },
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
    },
    components: { DeviceInfoComponent },
  };
  </script>
  
  <style scoped lang="scss">
  @import "../assets/demo/badges.scss";
  </style>
  